@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';

.container {
    max-width: 329px;

    .headerContainer {
        & > * + * {
            margin-left: spacing(2);
        }
    }

    .iconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        background-color: $color-positive-full;

        .icon {
            color: $color-white;

            svg {
                transform: scale(1.5);
            }
        }
    }

    .button {
        max-width: 130px;
    }
}
