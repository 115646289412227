@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';

.container {
    max-width: 384px;
    height: max-content;

    .dataPointsDetailCard {
        padding: spacing(4);
    }
}
