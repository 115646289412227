@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';

.container {
    width: 100%;
}

.imageWrapper {
    height: 280px;
    overflow: hidden;
    margin-bottom: spacing(3);
    border-radius: 2px;
}

.image {
    width: 100%;
}

.infoWrapper {
    display: flex;

    & > * + * {
        margin-left: spacing(5);
    }
}

.label {
    font-size: px-to-em(12);
    line-height: px-to-em(16);
    color: $color-grey;
}

.value {
    font-size: px-to-em(24);
    line-height: px-to-em(32);
    font-weight: bold;
}

.dataPointsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: spacing(2);
    width: 100%;
}

.dataPoint {
    max-width: unset;
}
