@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';

.container {
    display: flex;
}

.buildingDetail {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 484px;
    margin-right: spacing(10);
}

.rooms {
    flex-grow: 1;
}
