@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        margin-bottom: spacing(2);
        text-align: center;
        max-width: 336px;
    }

    .infoText {
        margin-bottom: spacing(2);
        text-align: center;
    }

    .buttonContainer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
    }
}
