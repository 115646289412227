@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';

.container {
    .inputContainer {
        display: grid;
        grid-template-columns: 328px 328px;
        column-gap: spacing(3);
        row-gap: spacing(3);
    }
}
