@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';

.container {
    display: grid;
    grid-template-columns: 384px 1fr;
    column-gap: spacing(5);
    row-gap: spacing(5);
    width: 100%;
    min-height: 611px;

    .lineGraphContainer {
        height: 611px;
    }

    .graphCard {
        padding: spacing(7.375);

        svg {
            padding: spacing(1);
        }
    }

    .customTooltip {
        background-color: $color-white;
        padding: spacing(2);
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 89px;
        height: 60px;
        border-radius: 4px;
        margin-bottom: spacing(2);
        box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.2);
    }

    .legend {
        margin-top: spacing(2);
        height: max-content;

        .legendItem {
            display: flex;
            align-items: center;
            .dot {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $color-negative-full;
                margin-right: spacing(1);
            }
        }
    }
}
