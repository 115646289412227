@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';

.scrollWrapper {
    width: 100%;
    overflow-x: hidden;
    padding-top: spacing(1);
    border-right: 1px solid $base-border-color-2;
}

.dataPointsWrapper {
    overflow-y: auto;
    white-space: nowrap;
    display: flex;

    & > * {
        flex-shrink: 0;
        & + * {
            margin-left: spacing(2);
        }
    }
}
