@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';

.legends {
    display: flex;
    flex-wrap: wrap;
}

.legent {
    display: flex;
    flex-wrap: wrap;

    text-transform: lowercase;

    &.extraMarginLeft {
        margin-left: spacing(1);
    }

    > div {
        display: flex !important;
        align-items: center;

        &:first-child {
            padding-left: 0 !important;
        }

        > span {
            &:not(:first-child) {
                margin-left: spacing(1) !important;
            }

            color: $color-dark-grey !important;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
        }
    }
}

.crosshair {
    > div:last-child {
        > div {
            > div {
                display: flex;
                flex-direction: column-reverse;

                div:first-child {
                    color: $color-dark-grey;
                    font-size: 0;
                    font-weight: normal;

                    span:last-child {
                        font-size: 14px;
                    }
                }
                div:not(:first-child) {
                    color: $color-dark-grey;
                    font-size: 0;
                    font-weight: bold;

                    span {
                        font-size: 14px;
                    }

                    span:not(:first-child) {
                        margin-left: spacing(0, 4);
                    }
                }
            }
        }
    }
}
