@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';

.container {
    padding: spacing(4);
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 58%, rgba(244, 244, 244, 1) 100%);
    display: flex;
    justify-content: center;

    .contentContainer {
        width: 640px;
        padding: spacing(4);

        .text {
            margin-top: spacing(1);
        }
    }
}
