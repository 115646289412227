@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';

.container {
    margin-left: 384px;
    .textContainer {
        max-width: 329px;

        .title {
            margin-bottom: spacing(1);
        }

        .alinea1 {
            margin-bottom: spacing(1);
        }
    }

    .submitButton {
        max-width: 148px;
    }

    .inputGrid {
        display: grid;
        grid-template-columns: 328px 328px;
        column-gap: spacing(3);
        row-gap: spacing(3);
    }

    .inputContainer {
        max-width: 328px;
        height: max-content;
        margin-bottom: spacing(2);

        & > * {
            width: 100%;
        }
    }
}
