@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';

.headingText {
    color: $color-grey;
}

.icon {
    transform: scale(0.75);

    path {
        fill: $color-grey;
    }
}

.isOpen {
    color: $color-dark-grey;

    path {
        fill: $color-dark-grey;
    }
}
