@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';

.activeLabel {
    display: flex;
    align-items: center;
    padding: spacing(1, -5) 0;
}

.activeLabelItem {
    display: flex;
    align-items: center;
}

.spacer {
    margin: 0 spacing(1);
}
