@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';

.container {
    display: flex;
    align-items: center;

    &.positive {
        .text {
            color: $color-positive-full;
        }

        .icon {
            svg {
                path,
                circle {
                    fill: $color-positive-full;
                }
            }
        }
    }

    &.average {
        .text {
            color: $color-warning-full;
        }

        .icon {
            svg {
                path,
                circle {
                    fill: $color-warning-full;
                }
            }
        }
    }

    &.negative {
        .text {
            color: $color-negative-full;
        }

        .icon {
            svg {
                path,
                circle {
                    fill: $color-negative-full;
                }
            }
        }
    }
}
