@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 246px;
    background-color: $color-light-grey-2;
    border-radius: 4px;
    padding: spacing(5.5);

    .dataPointsContainer {
        display: flex;
        width: 100%;
    }

    .title {
        margin-bottom: spacing(4);
    }
}
