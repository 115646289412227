@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';

.container {
    .textContainer {
        max-width: 329px;

        .title {
            margin-bottom: spacing(1);
        }

        .alinea1 {
            margin-bottom: spacing(1);
        }
    }

    .submitButton {
        max-width: 148px;
    }
}
