@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';

.container {
    display: flex;
    flex-direction: column;

    .title {
        margin-top: spacing(1);
    }
}
