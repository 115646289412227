@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';

.menu {
    padding: spacing(1);
    background-color: $color-white;
    border-radius: 4px;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
}

.dropdown {
    color: $base-text-color;
    z-index: 99;
    width: 328px;
}

.items {
    margin-top: spacing(1);
}

.dropdownWrapper {
    width: 100%;
}
