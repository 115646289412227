@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';

.dropdownItem {
    display: flex;
    align-items: center;
    padding: spacing(1, 4) spacing(2);
    background-color: $color-white;
    transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1);
    font-weight: 500;
    border-radius: 8px;
    font-size: px-to-em(12);
    color: $base-text-color;
    width: 100%;
    margin-bottom: spacing(1);

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: $base-text-color;
        background-color: $color-light-grey;
    }
}

.isDisabled {
}

.isActive {
    background-color: $color-mid-grey;
}
