@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';

.container {
    display: block;
    text-decoration: none;
    transition: 0.25s ease-in-out;

    &:hover {
        box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.2);
    }
}

.imageWrapper {
    width: 100%;
    height: 118px;
    background-color: $color-fog-grey;
    margin-bottom: spacing(2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
        width: 100%;
    }
}

.infoWrapper {
    display: flex;
    margin-bottom: spacing(3, 1);

    & > * + * {
        margin-left: spacing(5);
    }
}

.infoLabel {
    font-size: px-to-em(8);
    line-height: px-to-em(12);
    color: $color-grey;
    margin-bottom: spacing(1, -4);
}

.infoValue {
    font-weight: bold;
    font-size: px-to-em(14);
    line-height: px-to-em(20);
}

.title {
    margin-bottom: spacing(1);
}
