@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';

.container {
    display: flex;
    flex-direction: column;
    height: 100%;

    &.isCentered {
        justify-content: center;
    }
}
