@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';

.container {
    padding: spacing(5) spacing(4);
    margin: 0 auto;
    max-width: 640px;

    .buttonContainer {
        display: flex;
        justify-content: center;
        flex: 0 1 1 auto;
    }
}
