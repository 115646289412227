@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';

.container {
    width: 100%;
    padding: spacing(5);
    cursor: pointer;
    transition: 0.25s ease-in-out;

    &:hover {
        box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.2);
    }
}
