@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';

.container {
    min-height: 464px;
    max-width: 401px;

    .headerContainer {
        display: flex;
        justify-content: flex-end;
        margin-bottom: spacing(3.5);
    }

    .stepWrapper {
        display: flex;
        flex-direction: row;
    }

    .stepTextWrapper {
        margin-left: spacing(2);
    }

    .stepList {
        margin-top: spacing(4);
    }
}
