@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';

.infoWrapper {
    display: flex;

    & > * + * {
        margin-left: spacing(5);
    }
}

.label {
    font-size: px-to-em(12);
    line-height: px-to-em(16);
    color: $color-grey;
}

.value {
    font-size: px-to-em(24);
    line-height: px-to-em(32);
    font-weight: bold;
}
