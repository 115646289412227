@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';

.container {
    padding: spacing(2) spacing(4);
    max-width: 640px;
    margin: 0 auto;

    .buttonContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
