@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';

.container {
    display: grid;
    column-gap: spacing(5);
    row-gap: spacing(5);
    width: 100%;

    &.twoColumns {
        grid-template-columns: 1fr 1fr;
    }

    &.threeColumns {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
