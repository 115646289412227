@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_layout.scss';
@import 'node_modules/@unicaiot/unica-iot-gallery-buildinginsight/dist/styles/variables/_colors.scss';

.container {
    padding: spacing(1) spacing(2);
    border-radius: 100px;
    width: max-content;
    color: $color-white;

    .text {
        font-size: 12px;
    }
}

.isDark {
    background-color: $color-dark-grey;
}

.isGrey {
    background-color: $color-mid-grey;
}
